import { HttpClient, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiResponse } from '@app/shared/models/api.model';
import { Logger } from '@app/utils/log';
import { StorageKey, StorageWrapper } from '@core/storage';
import { environment } from '@environments/environment';
import { Observable, ReplaySubject, finalize, firstValueFrom, map, of, tap } from 'rxjs';
import { Role } from './roles';
import { OAuthService } from 'angular-oauth2-oidc';
import { CommonService } from '@app/shared/common.service';

export interface AccountProfileModel {
  UserID?: string,
  Username?: string,
  IsSuperUser?: string,
  Email?: string,
  DisplayName?: string,
  UpdatePassword?:string,
  UserStatus?: string,
  UserStatusDisplay?: string,
  BranchCode?: string,
  UserTitle?: string,
  Roles?:	[string],
  LastLoginDateTime?: string;
  RoleCode?: string
}

export interface UserInfo {
  [prop: string]: string;
}

export interface CredentialResponse extends ExpiredAccountInfo {
  AccessToken: string;
  AccessTokenID: string;
  RefreshToken: string;
  User: UserInfo;
}

export interface SetPasswordRequest {
  Username: string;
  Password: string;
  Token: string;
}
export interface ForgotPasswordRequest {
  Username: string;
  Action: string;
  Token: string;
}

export interface ExpiredAccountInfo {
  Username?: string,
  LastEditPasswordDateTime?: string | null,
  ExpirePasswordDateTime?: string | null
}

enum LOAD_STATUS {
  INIT,
  LOADING,
  LOADED,
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly API_URL = environment.apiUrl;
  private IsLoadingProfile = LOAD_STATUS.INIT;
  private ResetableProfile$ = new ReplaySubject<AccountProfileModel | null>(1);
  public readonly Profile$ = new ReplaySubject<AccountProfileModel | null>(1);
  public Profile: AccountProfileModel | null = null;
  public expiredAccountInfo: ExpiredAccountInfo | null = null;

  setExpiredAccountInfo(value: ExpiredAccountInfo) {
    this.expiredAccountInfo = value;
  }
  getExpiredAccountInfo(): ExpiredAccountInfo | null {
    return this.expiredAccountInfo;
  }

  constructor(
    private readonly router: Router,
    private http: HttpClient,
    private oauthService : OAuthService,
    private readonly commonService: CommonService,
  ) {
  }

  getSSOConfig() {
    return this.http.post<ApiResponse<{Issuer: string, ClientID: string}>>(`${this.API_URL}/auth/getssoconfig`, {});
  }

  loginSSO({ Code }: { Code: string }): Observable<{ success: boolean, error?: string, Status?: string }> {
    return this.http.post<ApiResponse<CredentialResponse>>(`${this.API_URL}/auth/authenticationSSO`, { Code }).pipe(
      tap((response) => {
        if (response.Data?.AccessTokenID) {
          const { AccessTokenID } = response.Data;
          this.setTokenID(AccessTokenID);
        }
        if (!response.Data?.AccessToken) {
          return;
        }
        const { AccessToken } = response.Data;
        this.setToken(AccessToken);
      }),
      map((response) => ({ success: response.Status === 'Success' && !!response.Data?.AccessToken, error: response.Description, Status: response.Status }))
    );
  }

  login({ Username, Password }: { Username: string, Password: string }): Observable<{ success: boolean, error?: string, Status?: string }> {
    return this.http.post<ApiResponse<CredentialResponse>>(`${this.API_URL}/auth/authentication`, { Username, Password }).pipe(
      tap((response) => {
        if (!response.Data?.AccessToken) {
          if (response.Status === 'ExpiredPassword') {
            Logger.log('Expired Password:', response.Data);
            const { Username, LastEditPasswordDateTime, ExpirePasswordDateTime } = response.Data;
            this.setExpiredAccountInfo({
              Username,
              LastEditPasswordDateTime,
              ExpirePasswordDateTime
            });
          }
          return;
        }
        const { AccessToken } = response.Data;
        this.setToken(AccessToken);
      }),
      map((response) => ({ success: response.Status === 'Success' && !!response.Data?.AccessToken, error: response.Description, Status: response.Status }))
    );
  }

  logout(rememberRoute = false) {
    //logout SSO
    const accessTokenID = StorageWrapper.get(StorageKey.ACCESS_TOKEN_ID);
    const codec = new HttpUrlEncodingCodec();
    const logoutSSOUrl = `${this.oauthService.logoutUrl}?post_logout_redirect_uri=${codec.encodeValue(window.location.origin)}&id_token_hint=${accessTokenID}`;
    const navigateOption = rememberRoute ? { queryParams: { returnUrl: this.router.url } } : undefined;

    // Call API to log out user (if needed)
    StorageWrapper.clear();
    this.IsLoadingProfile = LOAD_STATUS.INIT;
    this.Profile = null;
    return this.router.navigate(['/auth/login'], navigateOption).then(_ => window.location.href =logoutSSOUrl);
  }

  forgetPassword(resData: ForgotPasswordRequest) {
    return this.http.post<ApiResponse<Record<string, never>>>(`${this.API_URL}/auth/forgotpassword`, resData);
  }

  setPassword(reqData: SetPasswordRequest) {
    return this.http.post<ApiResponse<Record<string, never>>>(`${this.API_URL}/auth/setpassword`, reqData);
  }

  validatetoken(token: string) {
    return this.http.post<ApiResponse<Record<string, never>>>(`${this.API_URL}/auth/validatetoken`, { Token: token });
  }

  requestUpdatePassword(resData: { Username: string }) {
    return this.http.post<ApiResponse<object>>(`${this.API_URL}/auth/forgotpassword`, resData);
  }

  IsHasAnyRole(...roles: Role[]): boolean {
    if (!roles) return true;
    if (!this.currentUserValue) return false;

    if (this.currentUserValue.IsSuperUser === '1') return true;
    if (!this.currentUserValue.Roles) return false;

    return roles.some(r => this.currentUserValue!.Roles!.includes(r));
  }

  IsHasAnyPrefixRole(...prefs: string[]): boolean {
    if (!prefs) return true;
    if (!this.currentUserValue) return false;

    if (this.currentUserValue.IsSuperUser === '1') return true;
    if (!this.currentUserValue.Roles) return false;

    return prefs.some(pref => this.currentUserValue!.Roles!.some(ur => ur.startsWith(pref)));
  }

  public get currentUserValue() {
    return this.Profile;
  }

  public get currentUserID() {
    return Number(this.currentUserValue?.UserID ?? 0);
  }

  setToken(token: string) {
    StorageWrapper.set(StorageKey.ACCESS_TOKEN, token);
    this.loadProfileAsync(true);
  }

  setTokenID(tokenID: string) {
    StorageWrapper.set(StorageKey.ACCESS_TOKEN_ID, tokenID);
  }

  setProfile(profile: AccountProfileModel | null) {
    this.ResetableProfile$.next(profile);
    this.Profile$.next(profile);
    this.Profile = profile;
  }

  loadProfileAsync(force = false) {
    if (force || this.IsLoadingProfile === LOAD_STATUS.INIT) {
      if (!StorageWrapper.get(StorageKey.ACCESS_TOKEN)) {
        this.setProfile(null);
        this.IsLoadingProfile = LOAD_STATUS.LOADED;
      }
      else {
        this.ResetableProfile$ = new ReplaySubject<AccountProfileModel | null>(1);
        this.IsLoadingProfile = LOAD_STATUS.LOADING;
        this.http.post<ApiResponse<AccountProfileModel>>(`${this.API_URL}/account/profile`, {})
          .pipe(
            finalize(() => {
              this.IsLoadingProfile = LOAD_STATUS.LOADED;
            })
          )
          .subscribe((response) => {
            this.setProfile(response?.Data ?? null);
          });
      }
    }
    return this.ResetableProfile$;
  }

  getProfileAsync() {
    if (this.IsLoadingProfile === LOAD_STATUS.LOADED) {
      return of(this.Profile);
    }
    return this.loadProfileAsync();
  }

  IsAuthenicatedAsync() {
    return this.getProfileAsync().pipe(map(p => !!p));
  }

  IsHasAnyRoleAsync(...roles: Role[]) {
    return this.getProfileAsync().pipe(
      map(profile => {
        if (!profile) return false;

        for (const role of roles) {
          if (profile.Roles?.includes(role)) return true;
        }

        return false;
      })
    );
  }

  HasMasterDataRoleAsync() {
    return this.commonService.getPermissionsMasterData().pipe(
      map(roles => {
        if (!roles) return false;
        return this.IsHasAnyRole(...(roles as Role[]));
      })
    );
  }

  IsHasRoleAsync(role: Role) {
    return this.IsHasAnyRoleAsync(role);
  }

  IsAdministratorAsync() {
    return this.IsHasAnyRoleAsync(Role.Administrators);
  }

  async IsAdministrator(): Promise<boolean> {
    try {
      const result = await firstValueFrom(this.IsAdministratorAsync());
      return result;
    } catch (error) {
      return false;
    }
  }
}
